import React, { useEffect, useState, useRef, RefObject } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { useLocation, useNavigate } from "react-router-dom";
import classnames from "classnames";

// import exampleVideo from "../../assets/trailer.mp4";
import shrinkIcon from "../../assets/icons/shrink.png";
import replay10Icon from "../../assets/icons/replay10.png";
import playIcon from "../../assets/icons/play.png";
import pauseIcon from "../../assets/icons/pause.png";
import loadingIcon from "../../assets/icons/spinner.svg";

import Page from "../page/page";
import "./streamPage.scss";

const exampleVideo = "https://youtu.be/ofn9-OXIcq0";
const LoadingBuffer = () => (
  <div className="loadingContainer">
    <img src={loadingIcon} width="50px" height="50px" alt="" />
  </div>
);
//
const StreamPage = ({ episode }: { episode?: any }) => {
  //   const dispatch = useAppDispatch();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isBuffering, setIsBuffering] = useState(0);
  const nowBuffering = useRef([]);

  // Main: full game view
  const player1 = useRef<HTMLVideoElement>(null);
  // IRL
  const player2 = useRef<HTMLVideoElement>(null);
  const player3 = useRef<HTMLVideoElement>(null);
  // Player game views
  const player4 = useRef<HTMLVideoElement>(null);
  const player5 = useRef<HTMLVideoElement>(null);
  //Page
  const pageRef = useRef<HTMLDivElement>(null);

  const allPlayers: RefObject<HTMLVideoElement>[] = [
    player1,
    player2,
    player3,
    player4,
    player5,
  ];
  const [focusedPlayer, setFocusedPlayer] = useState(player1);
  const navigate = useNavigate();

  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");

  const playOrPauseStreams = () => {
    if (isPlaying) pauseStream();
    else playStream();
  };

  const stallStream = (index: number = 0) => {};

  const playStream = (index: number = 0) => {
    setIsPlaying(true);
    setIsBuffering(-1);
    console.log("Nb : " + nowBuffering?.current?.length);
    nowBuffering.current = [];
    allPlayers.forEach((player) => player?.current?.play());
    // switch (index) {
    //   case 1:
    //     player1?.current?.play();
    //     break;
    //   case 2:
    //     player2?.current?.play();
    //     break;
    //   default:
    //     setIsPlaying(true);
    //     allPlayers.forEach(player => player?.current?.play())
    //     // player1?.current?.play();
    //     // player2?.current?.play();
    //     break;
    // }
  };

  const pauseStream = (index: number = 0) => {
    setIsPlaying(false);
    allPlayers.forEach((player, i) => {
      if (!player?.current?.paused) {
        player?.current?.pause();
      }
    });
  };

  // Used if one video is waiting to be bufferized
  const pauseStreamBut = (index: number) => {
    setIsBuffering(index);
    console.log("buffering ", index);
    const realIndex = index;
    setIsPlaying(false);
    allPlayers
      .filter((p, i) => i !== realIndex)
      .forEach((player, i) => {
        player?.current?.pause();
        //@ts-ignore
        player.current.currentTime = allPlayers[realIndex].current?.currentTime;
      });
  };

  const stall = () => {
    player1?.current?.pause();
    player1?.current?.dispatchEvent(new Event("waiting"));
    setTimeout(() => {
      player1?.current?.play();
    }, 2000);
  };

  const back10s = () => {
    allPlayers.forEach((player) => {
      if (player?.current) {
        player.current.currentTime = player?.current?.currentTime - 10;
      }
    });
    playStream();
  };

  const exitStream = () => {
    pageRef.current?.animate(
      [
        { transform: "scale(1)", opacity: 1 },
        { transform: "scale(0.5)", opacity: 0 },
      ],
      { duration: 300 }
    );
    setTimeout(() => {
      navigate("/");
    }, 270);
  };

  useEffect(() => {
    let allPlayersInitialized = true;
    allPlayers.forEach((player) => {
      allPlayersInitialized =
        allPlayersInitialized &&
        player?.current !== null &&
        player?.current !== undefined;
    });

    if (allPlayersInitialized) {
      //@ts-ignore
      player1?.current?.addEventListener("canplaythrough", (event) => {
        setIsBuffering(-1);
      }); // Player 1 considered as Leader
      player1?.current?.addEventListener("ended", (event) => {
        setIsPlaying(false);
      });
      //   player1?.current?.addEventListener("waiting", (event) => {
      //     console.log("Video is waiting for more data.");
      //     if (player1?.current?.currentTime && player1?.current?.currentTime > 0)
      //       pauseStreamBut(1);
      //   });
      //   player1?.current?.addEventListener("playing", (event) => {
      //     console.log("Video is playing again.");
      //     playStream();
      //   });

      // Handling other players loading issues
      allPlayers.forEach((player, index) => {
        // But first disabling this weird option
        //@ts-ignore
        player.current.disablePictureInPicture = true;
        player?.current?.addEventListener("waiting", (event) => {
          console.log("Video " + index + " is waiting for more data.");
          if (player?.current?.currentTime && player?.current?.currentTime > 0)
            if (nowBuffering?.current?.length === 0) {
              console.log(nowBuffering?.current?.length);
              //@ts-ignore
              nowBuffering.current = [player];
              pauseStreamBut(index);
            }
        });
        player?.current?.addEventListener("playing", (event) => {
          console.log("Video " + index + " is playing again.");
          if (nowBuffering?.current[0] === player) {
            // nowBuffering.current = []
            playStream();
          }
        });
      });
    }
  }, [player1, player2, player3, player4, player5]);

  const focusOnPlayer = (ref: RefObject<HTMLVideoElement>) => {
    if (
      focusedPlayer !== ref &&
      focusedPlayer?.current?.parentElement &&
      ref?.current?.parentElement
    ) {
      //@ts-ignore
      const topPos = window.getComputedStyle(ref?.current?.parentElement).top;
      //@ts-ignore
      const leftPos = window.getComputedStyle(ref?.current?.parentElement).left;

      //@ts-ignore
      const topPosFocus = window.getComputedStyle(
        focusedPlayer?.current?.parentElement
      ).top;
      //@ts-ignore
      const leftPosFocus = window.getComputedStyle(
        focusedPlayer?.current?.parentElement
      ).left;

      //@ts-ignore
      focusedPlayer.current.parentElement.style.top = topPos;
      //@ts-ignore
      focusedPlayer.current.parentElement.style.left = leftPos;

      ref.current.parentElement.style.top = topPosFocus;
      ref.current.parentElement.style.left = leftPosFocus;
      setFocusedPlayer(ref);
    } else console.log("error", focusedPlayer?.current, player1?.current);
  };

  const resyncStream = () => {
    let minTime = player1?.current?.currentTime ?? 0;
    pauseStream();
    allPlayers.forEach(
      //@ts-ignore
      (player) => {
        minTime = Math.min(minTime, player?.current?.currentTime ?? 0);
      }
    );
    allPlayers.forEach(
      //@ts-ignore
      (player) => (player.current.currentTime = minTime)
    );
    playStream();
  };

  return (
    <div className="streamPage test" ref={pageRef}>
      <button className="exitButton" onClick={exitStream}>
        <img
          src={shrinkIcon}
          width="25px"
          height="25px"
          className="dataStyleIcon"
          alt=""
        />
      </button>
      <button className="exitButton2" onClick={exitStream}>
        <img
          src={shrinkIcon}
          width="25px"
          height="25px"
          className="dataStyleIcon"
          alt=""
        />
      </button>
      <iframe
        width="90%"
        height="90%"
        src="https://www.youtube.com/embed/ofn9-OXIcq0"
        title="trailer"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
      {/* <div className="mobileLandscapeMessage">
        Le format paysage n'est pas adapté à ce visionnage.
      </div>
      <div className="pageContent">
        <div
          className={classnames(
            "videoContainer",
            "masterVideo",
            focusedPlayer === player1 && "focusedVideo"
          )}
          onClick={() => focusOnPlayer(player1)}
        >
          {isBuffering === 0 && <LoadingBuffer />}
          <video width="100%" ref={player1} preload="auto">
            <source src={exampleVideo} type="video/mp4" />
          </video>
        </div>
        <div
          onClick={() => focusOnPlayer(player2)}
          className={classnames(
            "videoContainer",
            "IRLPlayer1Video",
            focusedPlayer === player2 && "focusedVideo"
          )}
        >
          {isBuffering === 1 && <LoadingBuffer />}
          <video width="100%" ref={player2} preload="auto">
            <source src={exampleVideo} type="video/mp4" />
          </video>
        </div>
        <div
          onClick={() => focusOnPlayer(player3)}
          className={classnames(
            "videoContainer",
            "IRLPlayer2Video",
            focusedPlayer === player3 && "focusedVideo"
          )}
        >
          {isBuffering === 2 && <LoadingBuffer />}
          <video width="100%" ref={player3} preload="auto">
            <source src={exampleVideo} type="video/mp4" />
          </video>
        </div>
        <div
          onClick={() => focusOnPlayer(player4)}
          className={classnames(
            "videoContainer",
            "inGamePlayer1Video",
            focusedPlayer === player4 && "focusedVideo"
          )}
        >
          {isBuffering === 3 && <LoadingBuffer />}
          <video width="100%" ref={player4} preload="auto">
            <source src={exampleVideo} type="video/mp4" />
          </video>
        </div>
        <div
          onClick={() => focusOnPlayer(player5)}
          className={classnames(
            "videoContainer",
            "inGamePlayer2Video",
            focusedPlayer === player5 && "focusedVideo"
          )}
        >
          {isBuffering === 4 && <LoadingBuffer />}
          <video width="100%" ref={player5} preload="auto">
            <source src={exampleVideo} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="controlBar">
        <button
          className="replay10sButton"
          onClick={back10s}
          disabled={isBuffering > -1}
        >
          <img
            src={replay10Icon}
            width="25px"
            height="25px"
            className="dataStyleIcon"
            alt=""
          />
        </button>
        <button
          className="playButton"
          onClick={() => playOrPauseStreams()}
          disabled={isBuffering > -1}
        >
          <img
            src={isPlaying ? pauseIcon : playIcon}
            width="25px"
            height="25px"
            className="dataStyleIcon"
            alt=""
          />
        </button>
        <button
          className="resyncButton"
          onClick={() => resyncStream()}
          disabled={isBuffering > -1}
        >
          R
        </button>
      </div> */}
    </div>
  );
};

export default StreamPage;
