
import allMUdras from "../../assets/secretBook/allMudras.png";

type ChapterSection = {
  title : string;
  text : string[];
  imageUrl? : string;
 };

type BookChapter = {
  title: string;
  subSections: ChapterSection[];
};



export const secretBookContent: BookChapter[] = [
  {
    title : "Gameplay Tips",
    subSections : [
      {
        title : "A. Tips génériques",
      text : [
        "1. Quand tu es touché, tu ne peux plus attaquer mais tu peux te déplacer.",
        "2. Pendant une invocation, tu ne peux plus te déplacer.",
        "3. Quand tu es en l'air, toutes les invocations nécessitant que tu poses ta main au sol ne sont plus activables.",
        "4. Quand tu es en l'air tu peux utiliser ton arme.",
      ]
    }
    ]
  },
  {
    title : "Conseils stratégiques",
    subSections : [
      {
        title : "A. Mana & Stratégie",
      text : [
        "1. Quand tu n'as plus de Mana, tes points de vie sont consommés. Attention ça fait très mal.",
        "2. Les invocations de défense consomment généralement moins de Mana que les invocations d'attaque.",
        "3. Les téléportations coûtent 1 Pt de Mana.",
        "4. Les lancers d'armes coûtent 1 Pt de Mana.",
      ]
    }
    ]
  },
  {
    title : "Memo mudras",
    subSections : [
      {
        title : "A. Rappels essentiels",
      text : [
        "1. Rappelle toi de toujours coller les mains pour passer en mode invocation",
        "2. Tu as 5s pour exécuter correctement tes 3 mudras",
        "3. Après tes 3 mudras, il y a toujours un geste de fin"
      ]    },
      {
        title : "B. Les trois enchaînements principaux",
      text : [],
      imageUrl: allMUdras
    }
    ]
  }
]
