import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import classnames from "classnames";

import worldIcon from "../../assets/icons/world.png";
import stats1Icon from "../../assets/icons/stats1.png";
import stats2Icon from "../../assets/icons/stats2.png";
import idCardIcon from "../../assets/icons/idCard.png";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import avatar_video_placeholder from "../../assets/players/avatar_video_placeholder.svg";

import "./statsPanel.scss";

const statsTabs = [
  { index: 0, icon: idCardIcon },
  { index: 1, icon: stats1Icon },
  { index: 2, icon: stats2Icon },
  { index: 3, icon: worldIcon },
];

const tmpTranslations: any = {
  attack: "Attaque",
  defense: "Défense",
  speed: "Vitesse",
  water: "Eau",
  earth: "Terre",
  wind: "Air",
  fire: "Feu",
  ether: "Ether",
  age: "Age",
  description: "Niveau",
  style: "Style",
  shokus: "Shokus (Nb)",
  visitedIslands: "Exploration (%)",
  team: "---",
};

const infosBubbles: any = {
  attack: "Les statistiques d'Attaque sont calculées lors de tests de force.",
  defense:
    "Les statistiques de Défense sont calculées lors de tests de gainage.",
  speed: "Les statistiques de Vitesse sont calculées via un test de sprint.",
  water: "La statistique Eau est calculée sur des tests de souplesse.",
  earth: "La statistique Terre est calculée sur la musculature.",
  wind: "La statistique Air est calculée sur des tests d'acrobaties.",
  fire: "La statistique Feu est calculée sur des tests de frappe.",
  ether: null,
  age: "test",
  city: "test",
  style: "test",
  shokus: "test",
  visitedIslands: "test",
  team: "test",
};

const InfoBubble = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="infoBubble" {...{ onClick }}>
      i
    </div>
  );
};

const ExitInfoBubble = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="exitInfoBubble" {...{ onClick }}>
      {/* <CloseSvg /> */}
      <div
        className="chevron right"
        style={{ transform: "translate(-1px, 4px)" }}
      ></div>
    </div>
  );
};

const StatData = ({
  value,
  maxValue,
  highlightColor = "#7cd9bb",
  barColor = "#7cd9bb",
}: {
  value: number;
  maxValue?: number;
  highlightColor?: string;
  barColor?: string;
}) => {
  const percent = !maxValue ? -1 : Math.trunc((value * 100) / maxValue);
  return !maxValue ? (
    <span className="statsData">{value}</span>
  ) : (
    <span className="statsData">
      <div
        className="statsBar"
        style={{
          backgroundImage: `linear-gradient(100deg,${barColor} ${percent}%, transparent ${
            percent + 1
          }% )`,
        }}
      ></div>
      <span style={{ width: "70px", textAlign: "right" }}>
        {value} / <span style={{ color: highlightColor }}>{maxValue}</span>
      </span>
    </span>
  );
};

const StatsPanel = ({ player }: { player: any }) => {
  //   const dispatch = useAppDispatch();
  //   const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");
  const [activeTab, setActiveTab] = useState(0);
  const [activeInfoBubble, setActiveInfoBubble] = useState(-1);

  const handleActiveTab = (index: number) => {
    setActiveTab(index);
    setActiveInfoBubble(-1);
  };

  useEffect(() => {
    setActiveTab(0);
    setActiveInfoBubble(-1);
  }, [player]);

  return (
    <div className="statsDisplayer">
      {activeTab === 0 && (
        <div className="stats idData">
          {isPortrait && (
            <div
              className="playerVideo"
              style={{ backgroundImage: `url(${player.picture})` }}
            >
              {/* <video src={player.avatar}
               width="auto"
               height="100%"
               autoPlay
               loop
               muted /> */}
              {/* <img
                src={avatar_video_placeholder}
                width="100%"
                height="auto"
                alt=""
              /> */}
            </div>
          )}
          <div className="idStats">
            {player.idData &&
              Object.keys(player.idData).map((stat: string) => (
                <span className="statsRow">
                  <span className="statsLabel">{tmpTranslations[stat]}</span>
                  <span className="statsData">{player.idData[stat]}</span>
                </span>
              ))}
          </div>
        </div>
      )}
      {activeTab === 1 && (
        <div className="stats stats1">
          {activeInfoBubble === 1 ? (
            <ExitInfoBubble onClick={() => setActiveInfoBubble(-1)} />
          ) : (
            <InfoBubble onClick={() => setActiveInfoBubble(1)} />
          )}
          {player.stats1 &&
            activeInfoBubble !== 1 &&
            Object.keys(player.stats1).map((stat: string) => (
              <span className="statsRow">
                <span className="statsLabel">{tmpTranslations[stat]}</span>
                <StatData
                  value={player.stats1[stat]}
                  maxValue={100}
                  highlightColor="#ffbc00"
                />
              </span>
            ))}
          {activeInfoBubble === 1 && (
            <div className="infoZone">
              <span>
                Ces statistiques reflètent les vraies aptitudes des combattants,
                calculées lors de tests physiques. <br />
                Les tests et algorithmes complets seront publiés dans le Secret
                Book.
              </span>
              {Object.keys(player.stats1).map(
                (stat: string) =>
                  infosBubbles[stat] && (
                    <div style={{ marginTop: 10 }}>
                      <span>{infosBubbles[stat]}</span>
                    </div>
                  ),
              )}
            </div>
          )}
        </div>
      )}
      {activeTab === 2 && (
        <div className="stats stats2">
          {activeInfoBubble === 2 ? (
            <ExitInfoBubble onClick={() => setActiveInfoBubble(-1)} />
          ) : (
            <InfoBubble onClick={() => setActiveInfoBubble(2)} />
          )}
          {player.stats2 &&
            activeInfoBubble !== 2 &&
            Object.keys(player.stats2).map((stat: string) => (
              <div className="statsRow">
                <span className="statsLabel">{tmpTranslations[stat]}</span>
                <StatData
                  value={player.stats2[stat]}
                  maxValue={200}
                  highlightColor="#7030a0"
                />
              </div>
            ))}
          {activeInfoBubble === 2 && (
            <div className="infoZone">
              <span>
                Les statistiques élémentaires reposent sur des tests physiques
                plus avancés. <br />
                Les tests et algorithmes complets seront publiés dans le Secret
                Book.
              </span>
              {Object.keys(player.stats2).map(
                (stat: string) =>
                  infosBubbles[stat] && (
                    <div style={{ marginTop: 10 }}>
                      <span>{infosBubbles[stat]}</span>
                    </div>
                  ),
              )}
            </div>
          )}
        </div>
      )}

      {activeTab === 3 && (
        <div className="stats worldStats">
          {player.statsGame &&
            Object.keys(player.statsGame).map((stat: string) => (
              <div className="statsRow">
                <span className="statsLabel">{tmpTranslations[stat]}</span>
                <span className="statsData">{player.statsGame[stat]}</span>
              </div>
            ))}
        </div>
      )}
      <div className="statsMenu">
        {statsTabs.map(({ icon, index }) => (
          <button
            className={classnames(
              "statsMenuButton",
              activeTab === index && "active",
            )}
            onClick={() => handleActiveTab(index)}
          >
            <img src={icon} alt="" />
          </button>
        ))}
      </div>
    </div>
  );
};

export default StatsPanel;
