import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import classnames from "classnames";

import Page from "../page/page";

import illu_chap1 from "../../assets/illu_chap1.svg";
import illu_chap2 from "../../assets/illu_chap2.svg";
import illu_chap3 from "../../assets/illu_chap3.svg";
import illu_chap4 from "../../assets/illu_chap4.svg";
import nopicture from "../../assets/nopicture.svg";
import pilot from "../../assets/pilot.png";
import episode0 from "../../assets/cards/kamenganomi.png";
import episode1 from "../../assets/cards/basu.png";
import episode2 from "../../assets/cards/mechakyojin.png";
import episode3 from "../../assets/cards/elasticogun.png";
import episode4 from "../../assets/cards/kirakiraawa.png";
import episode5 from "../../assets/cards/shotouBunshin.png";
import episode6 from "../../assets/cards/saikiSakujo.png";
import episode7 from "../../assets/cards/bachisoryou.png";
import episode8 from "../../assets/cards/kagemanshin.png";
import episode9 from "../../assets/cards/aisukuriimu.png";

import "./home.scss";

type Milestone = {
  id: number;
  type: "episode" | "event" | "coming";
  title: string;
  description: string;
  illu: any;
  link: string;
  subtitle?: string;
};

type Chapter = {
  id: number;
  number: number;
  title: string;
  illu: any;
};

const firstPages = [
  <>
    Dans le Shokai, il fut un temps très ancien où les peuples vivaient heureux.
    <br />
    Ère pacifique où naissaient des êtres valeureux, cette ère inspira de
    nombreuses âmes, et forgea de nombreux pouvoirs. <br />
    <br />
    Ainsi pendant cette période, naquirent huit bébés, nourris par la fougue et
    la chaleur de cette ère.
    <br />
    <br /> Huit bébés dont la force allait effrayer les plus téméraires, et le
    nom rester gravé dans l'histoire :<br /> les <i>Utawaleza</i>.
  </>,
  <>
    Quatre de ces bébés croyaient en une justice qui devait être exercée par des
    élus désignés. Ces élus détiendraient donc les pouvoirs nécessaires afin
    d’assurer le bonheur des peuples. <br />
    <br />
    Ils décidèrent donc de prêter leur pouvoir à Quatre empereurs, qu’ils
    estimaient en mesure d’assumer cette conception de la justice.
    <br /> Ces 4 bébés furent nommés, d’un commun accord populaire, les{" "}
    <i>Kenkyodomo</i>.<sup className="pageNoteNumber">(1)</sup>
    <div className="footNote">
      (1) Note du narrateur : Le son Kenkyo nous rappelle évidemment le mot
      japonais 謙虚 signifiant modeste, humble.
    </div>
  </>,
  <>
    Les quatre autres avaient une vision plus libérale de la justice, et
    estimaient qu’elle devrait se passer d’autorité suprême. Ils affirmaient que
    celle-ci devrait s'exprimer selon ses lois naturelles et non régulées, avec
    lesquelles ils interféreraient aussi peu que possible.
    <br />
    <br />
    Ces bébés décidèrent ainsi de conserver leur pouvoir, et les léguer
    éventuellement, par la suite, à un héritier qu’ils estimeraient digne.
    <br /> Ils furent nommés, encore une fois, suite à un consensus tacite et un
    ressenti général des peuples, les <i>Wosagonjetsekadomo</i>.{" "}
    <sup className="pageNoteNumber">(2)</sup>
    <div className="footNote">
      (2) Note du narrateur : L’appellation n’est pas sans rappeler le mot
      wosagonjetseka, qui signifie indomptable en langue Chichewa d’Afrique
      centrale et australe.
    </div>
  </>,
  <>
    Ces 8 bébés cohabitèrent très longtemps, et, malgré leurs divergences,
    furent les garants d’un monde paisible, car étaient tous appréciés au même
    titre par les peuples du Shokai. <br />
    <br /> Mais cet équilibre, qui semblait éternel et inébranlable, fut rompu.
    Pour des raisons encore obscures, dans un contexte plus que trouble, les
    Utawaleza disparurent. <br />
    <br />
  </>,
  <>
    Bien sûr, le monde n’en fut pas immédiatement perturbé, et beaucoup
    pensèrent que l’équilibre des éléments y survivrait malgré tout.
    <br />
    <br />
    Mais de nombreuses mécaniques, invisibles, étaient à l'œuvre. Au fil des
    siècles, l'instabilité grandissait, et le chaos menaçait d’éclore chaque
    jour un peu plus…
  </>,
  <>
    Plus de 400 ans plus tard, une lueur d’espoir surgit, qui pourrait mener,
    dans le meilleur des cas, à un nouvel équilibre durable.
    <br /> <br />
    Mais nul n’aurait deviné que cette lueur d’espoir viendrait d’un autre
    monde, sur lequel même l’habitant le plus fou du Shokai n’aurait pensé
    miser. <br />
    Un monde versatile, faible, égoïste et fainéant, voilà ce qu’ils en
    pensaient...
  </>,
  <>
    Pourtant c’est bien ce Monde qui allait jouer un rôle déterminant dans le
    destin du Shokai, car leurs destins étaient entremêlés bien au-delà de ce
    qu’il pouvait paraître. <br />
    <br />
    Ils allaient découvrir la détermination, la force, la passion et l’endurance
    dont était capable ce Monde jusqu’alors méconnu: le Monde Humain.
  </>,
];

const BookPage = ({ text, pageNumber }) => (
  <div className="bookpage">
    <div className="pageHeader">*</div>
    <div className="pageContent">{text}</div>
    <div className="pageFooter">- {pageNumber} -</div>
  </div>
);

const nextChaptersList: Chapter[] = [
  // {
  //   id: 1,
  //   number: 1,
  //   title: "Ile Tonaru : 1er Tenshi",
  //   illu: illu_chap1,
  // },
  {
    id: 2,
    number: 2,
    title: "Puissance. ",
    // title: "Ile Unmoroso",
    illu: illu_chap2,
  },
  {
    id: 3,
    number: 3,
    title: "Technique.",
    // title: "Ile Shinoky",
    illu: illu_chap3,
  },
  {
    id: 4,
    number: 4,
    title: "Préliminaires.",
    // title: "Ile Korosen",
    illu: illu_chap4,
  },
];

const milestones: Milestone[] = [
  // {
  //   id: 1,
  //   type: "episode",
  //   title: "... Janken Tournament !",
  //   description: `Bienvenue dans le Shotam Sekai !`,
  //   illu: pilot,
  //   link: "https://youtu.be/ofn9-OXIcq0",
  //   subtitle: "Release 1",
  // },
  {
    id: 1,
    type: "episode",
    title: "En cours",
    description: "Concentre ton Ki, Libère ton Chakra, Maitrise ta Mana.",
    illu: episode0,
    link: "",
    subtitle: "Kamenganomi",
  },
  {
    id: 2,
    type: "episode",
    title: "À venir",
    description: "De la force brute, à l'ancienne.",
    illu: episode1,
    link: "",
    subtitle: "Basu",
  },
  {
    id: 3,
    type: "episode",
    title: "À venir",
    description: "Plus haut, plus grand, plus fort.",
    illu: episode2,
    link: "",
    subtitle: "Mecha Kyojin",
  },
  {
    id: 4,
    type: "episode",
    title: "À venir",
    description: "Elastico Gun : une attaque en souplesse.",
    illu: episode3,
    link: "",
    subtitle: "Elastico Gun",
  },
  {
    id: 5,
    type: "episode",
    title: "À venir",
    description: "Un peu de beauté dans ce monde de brutes.",
    illu: episode4,
    link: "",
    subtitle: "Kira Kira Awa",
  },
  {
    id: 6,
    type: "episode",
    title: "À venir",
    description: "Deux pour le prix d'un.",
    illu: episode5,
    link: "",
    subtitle: "Shotou Bunshin",
  },
  // {
  //   id: 6,
  //   type: "episode",
  //   title: "Awa Awa",
  //   description:
  //     "Un peu de beauté dans ce monde de brutes. Vole, Flotte, Brille.",
  //   illu: episode5,
  //   link: "",
  //   subtitle: "Release 5",
  // },
  {
    id: 7,
    type: "episode",
    title: "À venir",
    description: "Quand l'attaque devient défense: un pari risqué !",
    illu: episode6,
    link: "",
    subtitle: "Erase",
  },
  {
    id: 8,
    type: "episode",
    title: "À venir",
    description: "Marche doucement, car tu marches sur mes rêves.",
    illu: episode7,
    link: "",
    subtitle: "Bachisoryou",
  },
  {
    id: 9,
    type: "episode",
    title: "À venir",
    description: "Fierté ou intelligence ? L'essence de nos ombres.",
    illu: episode8,
    link: "",
    subtitle: "Kage Manshin",
  },
  {
    id: 10,
    type: "episode",
    title: "À venir",
    description:
      "Pour un chapitre entamé sous le signe du feu, un final givré s'impose.",
    illu: episode9,
    link: "",
    subtitle: "Aisu Kurimu",
  },
];

const ChapterTimeline = ({ milestones }: { milestones: Milestone[] }) => {
  const elWidth =
    document.getElementById("timeline")?.getBoundingClientRect().width ?? 0;
  const scrollWidth = document.getElementById("timeline")?.scrollWidth ?? 0;

  const { ref, inView } = useInView({ threshold: 0.1 });
  const [scrollPerc, setScrollPerc] = useState(0);
  const navigate = useNavigate();

  const timelineScrollHandler = () => {
    const xScroll = document.getElementById("timeline")?.scrollLeft;

    setScrollPerc(xScroll ? (100 * xScroll) / (scrollWidth - elWidth) : 0);
  };

  const watchEpisode = (link: string) => {
    window.open(link);
    // navigate("stream");
  };

  useEffect(() => {
    //@ts-ignore
    document
      .getElementById("timeline")
      ?.addEventListener("scroll", timelineScrollHandler);
    return () => {
      //@ts-ignore
      document
        .getElementById("timeline")
        ?.removeEventListener("scroll", timelineScrollHandler);
    };
  });
  return (
    <div
      id="timeline"
      ref={ref}
      className={classnames("timeline", !inView ? "timelineNotInView" : "")}
    >
      {/* {scrollPerc > 10 && (
        <div className="chevronContainer left">
          <div className="chevron left"></div>
        </div>
      )} */}
      {milestones.map(
        ({ subtitle, title, type, description, illu, link, id }) => (
          <div className="episode">
            <div className="episodeTitleContainer">
              <h2 className="episodeTitle">Release #{id} </h2>
              <h2 className="episodePalier">{title} </h2>
            </div>
            <h2 className="episodeSubtitle">{subtitle} </h2>
            <img
              src={illu}
              width="200px"
              height="300px"
              alt=""
              style={{ opacity: illu === nopicture ? 0.5 : 1 }}
              className="episodeIllu"
            />
            {/* {type === "episode" && (
            <button
              className="seeEpisodeButton"
              onClick={() => watchEpisode(link)}
              disabled={link.length == 0}
            >
              {link.length > 0 ? "Voir le trailer" : "À venir"}
            </button>
          )} */}
            <div className="episodeText">
              <div className="episodeDescription">{description}</div>
            </div>
          </div>
        ),
      )}
      <div
        className={classnames(
          "chevronContainer",
          "right",
          scrollPerc > 2 ? "hidden" : "",
        )}
        // onClick={() => {
        //   //@ts-ignore
        //   document
        //     .getElementById("timeline")
        //     ?.scroll({ left: scrollWidth - elWidth });
        // }}
      >
        <div className="chevron right"></div>
      </div>
    </div>
  );
};

const ChapterTitle = ({
  number,
  title,
  illu,
  style,
}: {
  number: number;
  title: string;
  illu: any;
  style?: any;
}) => {
  return (
    <div className="chapterTitle" {...{ style }}>
      <img
        src={illu}
        width="50px"
        height="50px"
        alt=""
        className="chapterIcon"
      />
      <p className="chapterText">
        <h2 className="chapterIndex">CHAPITRE {number}</h2>
        <h3 className="chapterLabel">
          <div>{title}</div>
        </h3>
      </p>
    </div>
  );
};

const Home = ({
  visibilityRateFirstView,
}: {
  visibilityRateFirstView: number;
}) => {
  const { ref: ref1, inView: inView1 } = useInView();

  const getMainBackStyle = (rate: number) => {
    const speeding = 5;
    const newRate = Math.pow(rate, speeding);
    return {
      opacity: Math.max(1 - newRate, 0.1),
      // transform: `translateY(${450 * (1 - newRate)}px) `,
    };
  };

  return (
    <Page>
      {/* <div className="bookContainer">
        {firstPages.map((page, i) => (
          <BookPage text={page} pageNumber={i} />
        ))}
      </div> */}
      <>
        <div className="chapter chapter1">
          <ChapterTitle
            number={1}
            title="Dextérité"
            illu={illu_chap1}
            style={getMainBackStyle(visibilityRateFirstView)}
          />

          <div
            className="chapterDescription"
            style={getMainBackStyle(visibilityRateFirstView)}
          >
            <span style={{ color: "inherit" }}>
              {" "}
              Des techniques secrètes en mode Mudras.
              <br />
              Tendinite du petit doigt si t'es pas bien échauffé.
            </span>{" "}
            <br />
            Qui sera le plus habile des combattants ?
            <br />
            <br />
            Un nouveau pouvoir tous les mois, enfin on va essayer !
            <br />
            <span className="cursor">_</span>
            <div className="triUp"></div>
          </div>
          <ChapterTimeline {...{ milestones }} />
        </div>

        <div ref={ref1} className="divider"></div>
        {/* <div className="arc">
          Arc suivant : <br /> <h1>Shotam : Tenshi</h1>
          <h3> Le grand tournoi.</h3>
        </div> */}
        {inView1 && (
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "center",
              filter: "grayscale(1)",
              background: "#79d4ff",
              borderRadius: "10px",
            }}
          >
            {nextChaptersList.map(({ number, title, illu }) => (
              <ChapterTitle {...{ number, title, illu }} />
            ))}
          </div>
        )}
      </>
    </Page>
  );
};

export default Home;
