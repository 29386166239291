import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  RefObject,
} from "react";
// import { useInView } from "react-intersection-observer";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import Banner from "../banner/banner";
import Page from "../page/page";
import { ReactComponent as ZoomInIcon } from "../../assets/icons/zoomIn.svg";
import { ReactComponent as ZoomOutIcon } from "../../assets/icons/zoomOut.svg";
import { ReactComponent as ExpandIcon } from "../../assets/icons/expand.svg";
import { ReactComponent as FocusSquare } from "../../assets/icons/focusWhite.svg";

import { MAP_DATA, CELLS_TYPES } from "./mapData";

import "./mapPage.scss";
import classnames from "classnames";
import { useMediaQuery } from "@react-hook/media-query";

const CELL_NUMBER_X = 50;
const CELL_NUMBER_Y = 20;
const TOTAL_CELLS = CELL_NUMBER_X * CELL_NUMBER_Y;

const KNOWN_CELLS = MAP_DATA.map((cell) => ({
  ...cell,
  index: Math.max(cell.y - 1, 0) * CELL_NUMBER_X + cell.x,
}));

const MapContextBlock = ({
  focusedCellIndex,
}: {
  focusedCellIndex: number | null;
}) => {
  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");
  if (!focusedCellIndex) {
    focusedCellIndex = 0;
  }
  const posY = Math.trunc(focusedCellIndex / CELL_NUMBER_X);
  const posX = focusedCellIndex - posY * CELL_NUMBER_X;
  const foundCell = KNOWN_CELLS.find((cell) => cell.index === focusedCellIndex);

  return (
    <div
      className="contextBlock"
      style={{
        backgroundImage: isPortrait
          ? `url("${getImageForCell(foundCell)}")`
          : "",
      }}
    >
      <h2
        className={classnames(
          "polygonBg1",
          getCellClassname(foundCell?.index ?? -1),
        )}
      >
        {foundCell
          ? foundCell.name ?? CELLS_TYPES[foundCell.type.label].name
          : CELLS_TYPES["unknown"].name}
        <div className="coordinatesBadge">
          [Lat: {posX} / Long: {posY + 1}]
        </div>
      </h2>
      {!isPortrait && (
        <img
          src={getImageForCell(foundCell)}
          className="contextPicture"
          width="100%"
          alt=""
        />
      )}
      <div className="divider dense"></div>
      <h3 className="contextDescription">
        {foundCell
          ? foundCell.description ??
            CELLS_TYPES[foundCell.type.label].description
          : CELLS_TYPES["unknown"].description}
      </h3>
    </div>
  );
};

const getImageForCell = (cell) =>
  cell ? cell.picture ?? cell.type.picture : CELLS_TYPES.unknown.picture;

const WholeMap = (
  { handleFocus, focusedCell }: { handleFocus: any; focusedCell: any },
  ref: any,
) => {
  const cellWidth = ref?.current?.clientWidth
    ? ref?.current?.clientWidth / CELL_NUMBER_X - 2
    : 0;
  const [cells, setCells] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      //@ts-ignore
      setCells(Array(TOTAL_CELLS).fill(0));
      return;
    }, 500);
  }, []);

  return (
    <div className="wholeMap">
      {cells.map((cell, i) => {
        const matchingCell = KNOWN_CELLS.find((c) => c.index === i);
        return (
          <div
            className={classnames(
              "mapCell",
              getCellClassname(i),
              focusedCell === i ? "focusedCell" : "",
            )}
            style={{ width: cellWidth, height: cellWidth }}
            onClick={() => handleFocus(i)}
          >
            <FocusSquare />
            {matchingCell?.iconPath && (
              <img
                alt=""
                src={matchingCell.iconPath}
                width="100%"
                className="cellIcon"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

const MapTools = ({ zoomIn, zoomOut, resetTransform }: any) => {
  return (
    <div className="mapTools">
      <button onClick={() => zoomIn()}>
        <ZoomInIcon width={25} />
      </button>
      <button onClick={() => zoomOut()}>
        <ZoomOutIcon width={25} />
      </button>
      <button onClick={() => resetTransform()}>
        <ExpandIcon width={35} />
      </button>
    </div>
  );
};

const getCellClassname = (cellIndex: number) => {
  const foundCell = KNOWN_CELLS.find((cell) => cell.index === cellIndex);
  return foundCell ? foundCell.type.label : CELLS_TYPES.unknown.label;
};

const WholeMapWithFR = forwardRef(WholeMap);

const MapPage = () => {
  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");
  const containerRef = useRef<HTMLDivElement>(null);

  const [focusedZone, setFocusedZone] = useState(null);

  const handleFocus = (cellIndex: any) => {
    setFocusedZone(cellIndex);
  };

  return (
    <Page>
      {/* <div className="backPage"></div> */}
      <Banner
        texts={[
          ` Le Shokai, c'est le monde virtuel dans lequel se retrouvent les combattants`,
          `Il semble composé majoritairement d'eau, et d'une multitudes d'îles`,
          `Sur certaines îles, il y a des arènes, où se déroulent les affrontements`,
          `La majorité des îles et du Shokai n'a pas encore été explorée à ce jour`,
        ]}
      />
      <TransformWrapper
        initialScale={2}
        // initialPositionX={200}
        // initialPositionY={100}
        // minScale={5}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <div className="mapAndItemsWrapper">
            {!isPortrait && (
              <>
                <MapTools {...{ zoomIn, zoomOut, resetTransform }} />
                <MapContextBlock focusedCellIndex={focusedZone} />
              </>
            )}
            <div className="mapContainer" ref={containerRef}>
              {isPortrait && (
                <MapTools {...{ zoomIn, zoomOut, resetTransform }} />
              )}
              <TransformComponent
                wrapperStyle={{ height: "100%", display: "flex" }}
              >
                <WholeMapWithFR
                  ref={containerRef}
                  handleFocus={handleFocus}
                  focusedCell={focusedZone}
                />
              </TransformComponent>
              {isPortrait && <MapContextBlock focusedCellIndex={focusedZone} />}
            </div>
          </div>
        )}
      </TransformWrapper>
    </Page>
  );
};

export default MapPage;
