import React, { useState, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";

// import { useInView } from "react-intersection-observer";

import Banner from "../banner/banner";
import Page from "../page/page";

import "./SecretBookPage.scss";
import classNames from "classnames";

import {secretBookContent} from "./SecretBookData";

const SecretBookPage = () => {

  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");

  return (
    <>
      {/* <div className="backPage">{focusedPlayer.name}</div> */}
      <Page>
        <Banner
          texts={[
            `Ceci est le Secret Book !`,
            `Il contient toutes les informations utiles pour les combats.`,
            `Il évolue constamment ...`,
          ]}
        />
        <div className="bookWrapper">
        {secretBookContent.map(
          ({title, subSections}) => (
            <div className="subSection">
            <h1>{title}</h1>
              <>
                {
                subSections.map(({title, text, imageUrl}) => (
                  <>
                    <h2> {title}</h2>
                    <div className="bookText"> {text.map(line => (
                      <div>{line}</div>
                    ))}</div>
                    {imageUrl && <img src={imageUrl} width="100%" style={{maxWidth: "100%"}}/>}
                  </>
                ))
                }
              </>
              </div>
            )
          )
      }
          </div>
      </Page>
    </>
  );
};

export default SecretBookPage;
