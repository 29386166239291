import React, { ReactNode, useRef } from "react";

// import { ReactComponent as CloseSvg } from "../../assets/close.svg";

import "./modal.scss";

const CloseIcon = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <div className="closeIcon" onClick={handleClose}>
      <div className="closeBar"></div>
      <div className="closeBar"></div>
    </div>
  );
};
const Modal = ({
  children,
  handleClose,
}: {
  children: ReactNode | ReactNode[];
  handleClose: () => void;
}) => {
  const modalContentRef = useRef<HTMLElement>(null);
  const backdropRef = useRef<HTMLDivElement>(null);

  const handleCloseDelayed = () => {
    modalContentRef.current?.animate(
      [{ transform: "scale(1)" }, { transform: "scale(0)" }],
      { duration: 300, easing: "ease-out" }
    );
    backdropRef.current?.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: 300,
      easing: "ease-out",
    });
    setTimeout(() => handleClose(), 300);
  };

  const handleBackdropClick = (event: any) => {
    if (["modalContent", "modal", "backdrop"].includes(event.target.id)) {
      handleCloseDelayed();
    }
  };
  return (
    <div
      ref={backdropRef}
      className="backdrop"
      id="backdrop"
      onClick={handleBackdropClick}
    >
      <div className="modal" id="modal">
        <CloseIcon handleClose={handleCloseDelayed} />
        <span
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          id="modalContent"
          ref={modalContentRef}
        >
          {children}
        </span>
      </div>
    </div>
  );
};

export default Modal;
