import React, { useState, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";

// import { useInView } from "react-intersection-observer";

import Banner from "../banner/banner";
import Page from "../page/page";

import "./PrivacyPage.scss";
import classNames from "classnames";


const PrivacyPage = () => {

  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");

  return (
    <>
      {/* <div className="backPage">{focusedPlayer.name}</div> */}
      <Page>
        <Banner
          texts={[
            `Politique d'utilisation des données`,
          ]}
          style={{ "marginTop": "100px" }}
        />
        <div className="privacyText">
          Dans cette version 1.0.0 de Shotam, aucune donnée utilisateur n'est collectée.
        </div>
      </Page>
    </>
  );
};

export default PrivacyPage;
