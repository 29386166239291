import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { useMediaQuery } from "@react-hook/media-query";

import Topbar from "./components/topbar/topbar";
import Drawer from "./components/drawer/drawer";
import Header from "./components/header/header";
import Home from "./components/home/home";
import WelcomePage from "./components/welcomePage/welcomePage";
import ShoshiPage from "./components/shoshiPage/shoshiPage";
import CardsPage from "./components/cardsPage/cardsPage";
import MapPage from "./components/mapPage/mapPage";
import StreamPage from "./components/streamPage/streamPage";
import SecretBookPage from "./components/SecretBookPage/SecretBookPage";
import PrivacyPage from "./components/PrivacyPage/PrivacyPage";
import ContactPage from "./components/ContactPage/ContactPage";
import PartnersPage from "./components/PartnersPage/PartnersPage";

import { selectIsDrawerOpen } from "./app/navigationSlice";

import "./App.scss";
import { useAppSelector } from "./app/hooks";

const App = () => {
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const location = useLocation();
  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");

  const [visibilityRateFirstView, setVisibilityRateFirstView] = useState(1);

  const scrollHandler = (event: Event) => {
    const containerHeight = window?.innerHeight;
    const scrollHeight = document?.getElementById("app")?.scrollTop ?? 0;
    const visibility = 1 - Math.min(scrollHeight / containerHeight, 1);
    if (visibility > 0) {
      setVisibilityRateFirstView(visibility);
    }
  };

  useEffect(() => {
    //@ts-ignore
    document?.getElementById("app").addEventListener("scroll", scrollHandler);
    return () => {
      if (document?.getElementById("app")) {
        //@ts-ignore
        document
          ?.getElementById("app")
          .removeEventListener("scroll", scrollHandler);
      }
    };
  }, []);

  return (
    <>
      {/* <video
        src="videos/bg_loop.mp4"
        autoPlay
        loop
        muted
        className="backgroundVideo"
      /> */}
      <div
        className={classnames(
          "App",
          location.pathname !== "/" &&
          !location.pathname.includes("stream") &&
          "darkBg",
          // location.pathname == "/" && !isPortrait &&
          // "notScrollableApp"
        )}
        id="app"
      >
        {!location.pathname.includes("stream") && <Topbar />}
        {isDrawerOpen && <Drawer />}
        <Routes>
          <Route
            path="/story"
            element={
              <>
                <Header {...{ visibilityRateFirstView }} />
                <Home {...{ visibilityRateFirstView }} />
              </>
            }
          />
          <Route path="/" element={<WelcomePage />} />
          <Route path="/shoshis" element={<ShoshiPage />} />
          <Route path="/shokus" element={<CardsPage />} />
          <Route path="/shokai" element={<MapPage />} />
          <Route path="/stream" element={<StreamPage />} />
          <Route path="/secretbook" element={<SecretBookPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/partners" element={<PartnersPage />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
