import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import classnames from "classnames";

import worldIcon from "../../assets/icons/world.png";
import stats1Icon from "../../assets/icons/stats1.png";
import stats2Icon from "../../assets/icons/stats2.png";
import idCardIcon from "../../assets/icons/idCard.png";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";
import avatar_video_placeholder from "../../assets/players/AgaAga_Character.png.svg";

import "./cardStatsPanel.scss";

const statsTabs = [
  { index: 0, icon: idCardIcon },
  { index: 1, icon: stats1Icon },
  { index: 2, icon: stats2Icon },
  { index: 3, icon: worldIcon },
];

const tmpTranslations: any = {
  idData: "Général",
  id: "Id",
  name: "Nom",
  type: "Type",
  invocationMode: "Mode d'invocation",
  mudra: "Mudra d'invocation",
  stats1: "Bonus & Usage",
  attackBonus: "Bonus offensif",
  defenseBonus: "Bonus défensif",
  manaBurnRate: "Malus mana",
  stats2: "Affinités & Contraintes",
  water: "Eau",
  earth: "Terre",
  wind: "Air",
  fire: "Feu",
  statsGame: "Stratégie",
  copies: "Nb. d'exemplaires",
  discovered: "Qté en circulation",
  evolutions: "Evolutions possibles",
  knownCombinations: "Combinaisons",
};

const infosBubbles: any = {
  stats1: {
    attackBonus:
      "La puissance offensive d'un shoku dépend de la statistique d'attaque du combattant qui l'active, en agissant comme un multiplicateur.",
    defenseBonus:
      "La puissance défensive d'un shoku dépend de la statistique de défense du combattant qui l'active, en agissant comme un multiplicateur.",
    manaBurnRate:
      "Chaque invocation coûte de la mana. Certains Shokus consomment de la Mana à chaque seconde - attention !",
    attackAndDefense:
      "Une Anima de type Attaque prend pour cible les combattants, ou les autres Invocations de type Défense. Donc elle ne protège pas des attaques de l'adversaire.",
    interactions:
      "Quand 2 invocations s'affrontent, la plus puissante gagne et détruit l'autre. Mais elle reçoit aussi des dégâts égaux à la puissance de l'invocation vaincue, et devient elle même plus faible.",
  },
  stats2: {
    water:
      "Un shoku ne peut être utilisé que si les statistiques éléméntaires d'un joueur sont compatibles avec le Shoku.",
    earth:
      "Par exemple, si un Shoku a une affinité Eau de 180, un joueur ne peut l'utiliser que si son niveau Eau est supérieur à 180.",
    wind: "Les shokus de génération 0 ont des affinités très peu contraignantes, ce qui permet à tous les combattants de les utiliser.",
    fire: "",
  },
};

const InfoBubble = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="infoBubble" {...{ onClick }}>
      i
    </div>
  );
};

const ExitInfoBubble = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="exitInfoBubble" {...{ onClick }}>
      <div
        className="chevron right"
        style={{ transform: "translate(-1px, 4px)" }}
      ></div>
    </div>
  );
};

const StatData = ({
  value,
  maxValue,
  highlightColor = "#7cd9bb",
  barColor = "#7cd9bb",
}: {
  value: number;
  maxValue?: number;
  highlightColor?: string;
  barColor?: string;
}) => {
  const percent = !maxValue ? -1 : Math.trunc((value * 100) / maxValue);
  return !maxValue ? (
    <span className="statsData">{value}</span>
  ) : (
    <span className="statsData">
      <div
        className="statsBar"
        style={{
          backgroundImage: `linear-gradient(100deg,${barColor} ${percent}%, transparent ${
            percent + 1
          }% )`,
        }}
      ></div>
      <span style={{ width: "70px", textAlign: "right" }}>
        {maxValue === 100 ? (
          <>
            {value} <span style={{ color: highlightColor }}>%</span>
          </>
        ) : (
          <>
            {value} / <span style={{ color: highlightColor }}>{maxValue}</span>
          </>
        )}
      </span>
    </span>
  );
};

const CardStatsPanel = ({
  cardData = {},
  openCard,
}: {
  cardData: any;
  openCard: (card: any) => void;
}) => {
  //   const dispatch = useAppDispatch();
  //   const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [activeInfoBubble, setActiveInfoBubble] = useState(-1);

  const handleActiveTab = (index: number) => {
    setActiveTab(index);
    setActiveInfoBubble(-1);
  };

  useEffect(() => {
    if (wrapperRef.current) {
      isPortrait
        ? wrapperRef.current.animate(
            [
              { transform: "rotateY(0deg)" },
              { transform: "rotateY(360deg)" },
              // { filter: "brightness(1) blur(0px)" },
              // { filter: "brightness(1) blur(0px)" },
              // { filter: "brightness(1) blur(0px)" },
            ],
            { duration: 500, easing: "ease-in-out" },
          )
        : wrapperRef.current.animate(
            [
              { transform: "rotateX(0deg)" },
              { transform: "rotateX(360deg)" },
              // { filter: "brightness(1) blur(0px)" },
              // { filter: "brightness(1) blur(0px)" },
              // { filter: "brightness(1) blur(0px)" },
            ],
            { duration: 500, easing: "ease-in-out" },
          );
    }
    setActiveTab(0);
    setActiveInfoBubble(-1);
  }, [cardData]);

  return (
    <div className="cardDetailsWrapper" ref={wrapperRef}>
      {/* {!isPortrait && ( */}
      <div className="cardImage" onClick={() => openCard(cardData)}>
        <img src={cardData.picture} alt="" width="100%" height="auto" />
        <div className="cardImageTip">Cliquer pour voir la carte</div>
      </div>
      {/* )} */}
      <div className="cardStatsDisplayer">
        {activeTab === 0 && (
          <div className="stats idData">
            <div className="idStats">
              <span className="statsTitle">{tmpTranslations["idData"]}</span>
              {cardData?.idData &&
                Object.keys(cardData.idData).map((stat: string) => (
                  <span className="statsRow">
                    <span className="statsLabel">{tmpTranslations[stat]}</span>
                    <span className="statsData">
                      {cardData.idData[stat] ?? "--"}
                    </span>
                  </span>
                ))}
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div className="stats stats1">
            {activeInfoBubble === 1 ? (
              <ExitInfoBubble onClick={() => setActiveInfoBubble(-1)} />
            ) : (
              <InfoBubble onClick={() => setActiveInfoBubble(1)} />
            )}
            <span className="statsTitle">{tmpTranslations["stats1"]}</span>
            {cardData.stats1 &&
              activeInfoBubble !== 1 &&
              Object.keys(cardData.stats1).map((stat: string) => (
                <span className="statsRow">
                  <span className="statsLabel">{tmpTranslations[stat]}</span>
                  <StatData
                    value={cardData.stats1[stat]}
                    maxValue={100}
                    highlightColor="#ffbc00"
                  />
                </span>
              ))}
            {activeInfoBubble === 1 && (
              <ul className="infoZone">
                {Object.keys(infosBubbles.stats1).map(
                  (stat: string) =>
                    infosBubbles.stats1[stat] && (
                      <li style={{ marginTop: 10 }}>
                        <span>{infosBubbles.stats1[stat]}</span>
                      </li>
                    ),
                )}
              </ul>
            )}
          </div>
        )}
        {activeTab === 2 && (
          <div className="stats stats2">
            <span className="statsTitle">{tmpTranslations["stats2"]}</span>
            {activeInfoBubble === 2 ? (
              <ExitInfoBubble onClick={() => setActiveInfoBubble(-1)} />
            ) : (
              <InfoBubble onClick={() => setActiveInfoBubble(2)} />
            )}
            {cardData.stats2 &&
              activeInfoBubble !== 2 &&
              Object.keys(cardData.stats2).map((stat: string) => (
                <div className="statsRow">
                  <span className="statsLabel">{tmpTranslations[stat]}</span>
                  <StatData
                    value={cardData.stats2[stat]}
                    maxValue={200}
                    highlightColor="#7030a0"
                  />
                </div>
              ))}
            {activeInfoBubble === 2 && (
              <ul className="infoZone">
                {Object.keys(infosBubbles.stats2).map(
                  (stat: string) =>
                    infosBubbles.stats2[stat] && (
                      <li style={{ marginTop: 10 }}>
                        <span>{infosBubbles.stats2[stat]}</span>
                      </li>
                    ),
                )}
              </ul>
            )}
          </div>
        )}

        {activeTab === 3 && (
          <div className="stats worldStats">
            <span className="statsTitle">{tmpTranslations["statsGame"]}</span>

            {cardData.statsGame &&
              Object.keys(cardData.statsGame).map((stat: string) => (
                <div className="statsRow">
                  <span className="statsLabel">{tmpTranslations[stat]}</span>
                  <span className="statsData">{cardData.statsGame[stat]}</span>
                </div>
              ))}
          </div>
        )}
        <div className="statsMenu">
          {statsTabs.map(({ icon, index }) => (
            <button
              className={classnames(
                "statsMenuButton",
                activeTab === index && "active",
              )}
              onClick={() => handleActiveTab(index)}
            >
              <img src={icon} alt="" />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardStatsPanel;
