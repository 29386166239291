import katonsumibi from "../../assets/cards/katonSumibi.png";
import bougudobei from "../../assets/cards/bougudobei.png";
import kaekaekichi from "../../assets/cards/kaekaekichi.png";
import aisukuriimu from "../../assets/cards/aisukuriimu.png";
import bachisoryou from "../../assets/cards/bachisoryou.png";
import mechakyojin from "../../assets/cards/mechakyojin.png";
import kagemanshin from "../../assets/cards/kagemanshin.png";
import kirakiraawa from "../../assets/cards/kirakiraawa.png";
import saikisakujo from "../../assets/cards/saikiSakujo.png";
import shotoubunshin from "../../assets/cards/shotouBunshin.png";
import kamenganomi from "../../assets/cards/kamenganomi.png";
import hunter from "../../assets/cards/hunter.png";
import cheenson from "../../assets/cards/cheenson.png";
import shokuHidden from "../../assets/cards/shoku_hidden.png";
import mizutama from "../../assets/cards/mizutama.png";

export const collectibleCards: any[] = [
  {
    id: 1,
    picture: katonsumibi,
    description: (
      <ul>
        <li>
          La boule de feu subtile. <br />
        </li>
        <li>
          Ce shoku permet de souffler une boule de feu sur son adversaire.
        </li>
        <li>Il est très difficile de viser juste en soufflant.</li>
        <div className="divider dense"> </div>
        <li>Shoku de génération 0.</li>
      </ul>
    ),
    idData: {
      name: "火遁 炭火 / Katon Sumibi",
      type: "Artefact",
      invocationMode: "Mudra",
      mudra: null,
    },
    stats1: {
      attackBonus: 5000,
      defenseBonus: 0,
      manaBurnRate: 10,
    },
    stats2: {
      water: 1,
      earth: 1,
      wind: 1,
      fire: 1,
    },
    statsGame: {
      copies: 1,
      discovered: 0,
      evolutions: 0,
      knownCombinations: ["???"],
    },
  },
  {
    id: 1,
    picture: bougudobei,
    description: (
      <ul>
        <li>
          Le mur protecteur. <br />
        </li>
        <li>
          Ce shoku permet d'invoquer un mur protégeant dans une direction.
        </li>
        <li>
          Il peut aussi être utilisé pour préparer sa prochaine attaque, ou
          cacher ses déplacements.
        </li>
        <div className="divider dense"> </div>
        {/* <li>
          Ce shoku est une version primaire d'un shoku plus puissant.
          <br />
          Sa version complète apparaîtra dans l'Arc 1.
        </li> */}
        <li>Shoku de génération 0.</li>
      </ul>
    ),
    idData: {
      name: "防具土塀 / Bougu Dobei",
      type: "Artefact x Défense",
      invocationMode: "Mudra",
      mudra: null,
    },
    stats1: {
      attackBonus: 0,
      defenseBonus: 5500,
      manaBurnRate: 5,
    },
    stats2: {
      water: 1,
      earth: 1,
      wind: 1,
      fire: 1,
    },
    statsGame: {
      copies: 1,
      discovered: 0,
      evolutions: 0,
      knownCombinations: ["???"],
    },
  },
  {
    id: 12,
    picture: kaekaekichi,
    description: (
      <ul>
        <li>
          Kaekaekichi, la grenouille espiègle. <br />
        </li>
        <li>Invoque une grenouille Ninja.</li>
        <li>
          Kaekaekichi suit son invocateur, et attaque quand elle repère un
          adversaire à portée.
        </li>
        <div className="divider dense"> </div>
        <li>Portée d'attaque : 1200</li>
        <li>Vitesse de déplacement : 1000</li>
        <div className="divider dense"> </div>
        <li>Shoku de génération 0.</li>
      </ul>
    ),
    idData: {
      name: "かえかえきち / Kaekaekichi",
      type: "Anima x Attaque",
      invocationMode: "Mudra",
      mudra: null,
    },
    stats1: {
      attackBonus: 5500,
      defenseBonus: 0,
      manaBurnRate: 15,
    },
    stats2: {
      water: 1,
      earth: 1,
      wind: 1,
      fire: 1,
    },
    statsGame: {
      copies: 1,
      discovered: 0,
      evolutions: 0,
      knownCombinations: ["???"],
    },
  },
  {
    id: 12,
    picture: kamenganomi,
    description: (
      <ul>
        <li>
          Concentration de Mana. <br />
        </li>
        <li>Ce shoku est une fatality.</li>
        <li>
          Il permet d'accumuler sa mana et de la relâcher dans un rayon
          surpuissant.
        </li>
        <li>La large amplitude du rayon facile la visée.</li>
        <div className="divider dense"> </div>
        <div className="divider dense"> </div>
        <li>Shoku de génération 0.</li>
      </ul>
    ),
    idData: {
      name: "かめんんがのみ / Kamenganomi",
      type: "Wild",
      invocationMode: "Pose",
      mudra: null,
    },
    stats1: {
      attackBonus: Infinity,
      defenseBonus: 0,
      manaBurnRate: 7.5,
    },
    stats2: {
      water: 1,
      earth: 1,
      wind: 1,
      fire: 1,
    },
    statsGame: {
      copies: 1,
      discovered: 0,
      evolutions: 0,
      knownCombinations: ["None"],
    },
  },
  {
    id: 2,
    picture: hunter,
    description: (
      <ul>
        <li>Passages souterrains - Vokn style.</li>
        <li>
          Le combattant invoque un tunnel qui lui permet de se déplacer sous
          terre.
        </li>
        <li>Il peut en sortir à 4 points stratégiques.</li>
        <li>
          Utile pour une attaque surprise, se protéger d'une attaque, ou
          recharger sa Mana.
        </li>
        <div className="divider dense"> </div>
        <li>Shoku de génération 0.</li>
      </ul>
    ),
    idData: {
      name: "ウンタ/ Hunter",
      type: "Artefact x Défense",
      invocationMode: "Mudra",
      mudra: null,
    },
    stats1: {
      attackBonus: 0,
      defenseBonus: 0,
      manaBurnRate: 10,
    },
    stats2: {
      water: 1,
      earth: 1,
      wind: 1,
      fire: 1,
    },
    statsGame: {
      copies: 1,
      discovered: 0,
      evolutions: 0,
      knownCombinations: ["???"],
    },
  },
  {
    id: 2,
    picture: mizutama,
    description: (
      <ul>
        <li>La sphère d'eau protectrice.</li>
        <li>Invoque une sphère d'eau, qui offre une protection à 360°. </li>
        <li>
          Peut aussi être utile pour piéger un adversaire et l'empêcher de fuir.
        </li>
        <div className="divider dense"> </div>
        <li>Shoku de génération 0.</li>
      </ul>
    ),
    idData: {
      name: "水玉 / Mizutama",
      type: "Artefact x Défense",
      invocationMode: "Mudra",
      mudra: null,
    },
    stats1: {
      attackBonus: 0,
      defenseBonus: 4500,
      manaBurnRate: 5,
    },
    stats2: {
      water: 1,
      earth: 1,
      wind: 1,
      fire: 1,
    },
    statsGame: {
      copies: 1,
      discovered: 0,
      evolutions: 0,
      knownCombinations: ["???"],
    },
  },
  {
    id: 1,
    picture: cheenson,
    description: (
      <ul>
        <li>Cheenson, la tronçonneuse timide.</li>
        <li>Invoque une tronçonneuse dotée d'un esprit.</li>
        <li>
          Cheenson suit son invocateur, et attaque quand elle repère un
          adversaire à portée.{" "}
        </li>
        <li>Inutile contre une cible volante.</li>
        <div className="divider dense"> </div>
        <li>Portée d'attaque : 1000</li>
        <li>Vitesse de déplacement : 1000</li>
        <div className="divider dense"> </div>
        <li>Shoku de génération 0.</li>
      </ul>
    ),
    idData: {
      name: "チェーンソー / Cheenson",
      type: "Anima x Attaque",
      invocationMode: "Mudra",
      mudra: null,
    },
    stats1: {
      attackBonus: 6500,
      defenseBonus: 0,
      manaBurnRate: 10,
    },
    stats2: {
      water: 1,
      earth: 1,
      wind: 1,
      fire: 1,
    },
    statsGame: {
      copies: 1,
      discovered: 0,
      evolutions: 0,
      knownCombinations: ["???"],
    },
  },
  // {
  //   id: 2,
  //   picture: aisukuriimu,
  //   description: (
  //     <ul>
  //       <li>
  //         Ce shoku est encore inconnu à ce jour. <br />
  //       </li>
  //       <li>Shoku de génération 0.</li>
  //     </ul>
  //   ),
  //   idData: {
  //     name: "アイスクリーム",
  //     type: "--",
  //     invocationMode: "--",
  //     mudra: null,
  //   },
  //   stats1: {
  //     attackBonus: 0,
  //     defenseBonus: 0,
  //     manaBurnRate: 0,
  //   },
  //   stats2: {
  //     water: 0,
  //     earth: 0,
  //     wind: 0,
  //     fire: 0,
  //   },
  //   statsGame: {
  //     copies: 0,
  //     discovered: 0,
  //     evolutions: 0,
  //     knownCombinations: ["???"],
  //   },
  // },
  // {
  //   id: 2,
  //   picture: bachisoryou,
  //   description: (
  //     <ul>
  //       <li>
  //         Ce shoku est encore inconnu à ce jour. <br />
  //       </li>
  //       <li>Shoku de génération 0.</li>
  //     </ul>
  //   ),
  //   idData: {
  //     name: "罰当所領",
  //     type: "--",
  //     invocationMode: "--",
  //     mudra: null,
  //   },
  //   stats1: {
  //     attackBonus: 0,
  //     defenseBonus: 0,
  //     manaBurnRate: 0,
  //   },
  //   stats2: {
  //     water: 0,
  //     earth: 0,
  //     wind: 0,
  //     fire: 0,
  //   },
  //   statsGame: {
  //     copies: 0,
  //     discovered: 0,
  //     evolutions: 0,
  //     knownCombinations: ["???"],
  //   },
  // },
  // {
  //   id: 2,
  //   picture: mechakyojin,
  //   description: (
  //     <ul>
  //       <li>
  //         Ce shoku est encore inconnu à ce jour. <br />
  //       </li>
  //       <li>Shoku de génération 0.</li>
  //     </ul>
  //   ),
  //   idData: {
  //     name: "土偶 巨人",
  //     type: "--",
  //     invocationMode: "--",
  //     mudra: null,
  //   },
  //   stats1: {
  //     attackBonus: 0,
  //     defenseBonus: 0,
  //     manaBurnRate: 0,
  //   },
  //   stats2: {
  //     water: 0,
  //     earth: 0,
  //     wind: 0,
  //     fire: 0,
  //   },
  //   statsGame: {
  //     copies: 0,
  //     discovered: 0,
  //     evolutions: 0,
  //     knownCombinations: ["???"],
  //   },
  // },
  // {
  //   id: 2,
  //   picture: kagemanshin,
  //   description: (
  //     <ul>
  //       <li>
  //         Ce shoku est encore inconnu à ce jour. <br />
  //       </li>
  //       <li>Shoku de génération 0.</li>
  //     </ul>
  //   ),
  //   idData: {
  //     name: "影 慢心",
  //     type: "--",
  //     invocationMode: "--",
  //     mudra: null,
  //   },
  //   stats1: {
  //     attackBonus: 0,
  //     defenseBonus: 0,
  //     manaBurnRate: 0,
  //   },
  //   stats2: {
  //     water: 0,
  //     earth: 0,
  //     wind: 0,
  //     fire: 0,
  //   },
  //   statsGame: {
  //     copies: 0,
  //     discovered: 0,
  //     evolutions: 0,
  //     knownCombinations: ["???"],
  //   },
  // },
  // {
  //   id: 2,
  //   picture: kirakiraawa,
  //   description: (
  //     <ul>
  //       <li>
  //         Ce shoku est encore inconnu à ce jour. <br />
  //       </li>
  //       <li>Shoku de génération 0.</li>
  //     </ul>
  //   ),
  //   idData: {
  //     name: "きらきら 泡",
  //     type: "--",
  //     invocationMode: "--",
  //     mudra: null,
  //   },
  //   stats1: {
  //     attackBonus: 0,
  //     defenseBonus: 0,
  //     manaBurnRate: 0,
  //   },
  //   stats2: {
  //     water: 0,
  //     earth: 0,
  //     wind: 0,
  //     fire: 0,
  //   },
  //   statsGame: {
  //     copies: 0,
  //     discovered: 0,
  //     evolutions: 0,
  //     knownCombinations: ["???"],
  //   },
  // },
  // {
  //   id: 2,
  //   picture: saikisakujo,
  //   description: (
  //     <ul>
  //       <li>
  //         Ce shoku est encore inconnu à ce jour. <br />
  //       </li>
  //       <li>Shoku de génération 0.</li>
  //     </ul>
  //   ),
  //   idData: {
  //     name: "才器 削除",
  //     type: "--",
  //     invocationMode: "--",
  //     mudra: null,
  //   },
  //   stats1: {
  //     attackBonus: 0,
  //     defenseBonus: 0,
  //     manaBurnRate: 0,
  //   },
  //   stats2: {
  //     water: 0,
  //     earth: 0,
  //     wind: 0,
  //     fire: 0,
  //   },
  //   statsGame: {
  //     copies: 0,
  //     discovered: 0,
  //     evolutions: 0,
  //     knownCombinations: ["???"],
  //   },
  // },
  // {
  //   id: 2,
  //   picture: shotoubunshin,
  //   description: (
  //     <ul>
  //       <li>
  //         Ce shoku est encore inconnu à ce jour. <br />
  //       </li>
  //       <li>Shoku de génération 0.</li>
  //     </ul>
  //   ),
  //   idData: {
  //     name: "初等 分身",
  //     type: "--",
  //     invocationMode: "--",
  //     mudra: null,
  //   },
  //   stats1: {
  //     attackBonus: 0,
  //     defenseBonus: 0,
  //     manaBurnRate: 0,
  //   },
  //   stats2: {
  //     water: 0,
  //     earth: 0,
  //     wind: 0,
  //     fire: 0,
  //   },
  //   statsGame: {
  //     copies: 0,
  //     discovered: 0,
  //     evolutions: 0,
  //     knownCombinations: ["???"],
  //   },
  // },
];
