import React, { useEffect, useState } from "react";

import background from "../../assets/background.svg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";

import "./header.scss";

const TITLE = "Arc 0";
const SUBTITLE = `Shotam : JANKEN`;
const DESCRIPTION = (
  <span>
    Quand les Animes deviennent réalité, tout Pouvoir doit être mérité.
    <br />
    <br />1 arène virtuelle, 2 équipes, et une infinité de pouvoirs.
    <br /> <br /> Seuls les plus forts survivront.
  </span>
);
const CAPTION = "Voir le trailer";

// const TITLE = "Arc 0";
// const SUBTITLE = `Shotam : Zenshin`;
// const DESCRIPTION = (
//   <span>
//     Des bastons de fou à la Naruto.
//     <br />
//     Un monde virtuel pour les combattants les plus allumés du monde.
//     <br />
//     Ca arrive, c'est le Shotam Sekai.
//   </span>
// );
// const CAPTION = "Premier épisode : 01/05/2022";

const Header = ({
  visibilityRateFirstView,
}: {
  visibilityRateFirstView: number;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [subtitle, setSubtitle] = useState("");
  const [subtitleAnim, setSubtitleAnim] = useState<NodeJS.Timeout>();

  const [introEnded, setIntroEnded] = useState(true);
  // const [visibilityRateFirstView, setVisibilityRateFirstView] = useState(1);

  useEffect(() => {
    setTimeout(() => setIntroEnded(true), 5000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const sub = setInterval(() => {
        if (subtitle.length < SUBTITLE.length && introEnded) {
          setSubtitle((oldSubtitle) =>
            SUBTITLE.slice(0, oldSubtitle.length + 1),
          );
        } else if (subtitleAnim) {
          clearInterval(subtitleAnim);
        }
      }, 50);
      if (!subtitleAnim) setSubtitleAnim(sub);
    }, 1000);
  }, []);

  const getMainBackStyle = (rate: number) => {
    const speeding = 5;
    const newRate = Math.pow(rate, speeding);
    return {
      opacity: newRate,
      // transform: `translateY(${450 * (1 - newRate)}px) `,
    };
  };

  return (
    <>
      <header className="App-header">
        {introEnded && (
          <>
            <div
              className="textBlock"
              style={getMainBackStyle(visibilityRateFirstView)}
            >
              <div className="title">{TITLE}</div>
              <div className="subtitle">
                {subtitle?.length > 0 ? subtitle : "_"}
                <div className="subsubtitle">じゃん拳</div>
              </div>
              <div className="description">{DESCRIPTION}</div>
              {/* <button className="caption" onClick={() => navigate("stream")}>
                {CAPTION}
              </button> */}
            </div>
          </>
        )}
      </header>
    </>
  );
};

export default Header;
