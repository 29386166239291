import React, { useEffect } from "react";
import classnames from "classnames";
import type { ComponentPropsWithoutRef } from "react";

import "./page.scss";

interface PageProps extends ComponentPropsWithoutRef<"div"> {
  children: React.ReactElement | React.ReactElement[];
  darkenBg?: boolean;
}

const Page = ({
  children,
  darkenBg,
  ...rest
}: PageProps) => {

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classnames("pageWrapper")}>
      <div className={classnames("page")}  {...rest}>{children}</div>
    </div>
  );
};

export default Page;
