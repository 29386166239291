import React, { useEffect, useState } from "react";
import classnames from "classnames";

import "./drawerButton.scss";
import { selectIsDrawerOpen, toggleDrawer } from "../../app/navigationSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const DrawerButton = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const [isDrawerClosing, setIsDrawerClosing] = useState(false);

  const handleToggleDrawer = () => {
    if (isDrawerOpen) {
      setIsDrawerClosing(true);
      const drawer = document.getElementById("drawer");
      drawer?.animate(
        [
          // keyframes
          { height: "100vh" },
          { height: "0vh" },
          { height: "0px" },
        ],
        {
          // timing options
          duration: 700,
          easing: "ease-out",
        }
      );
      setTimeout(() => {
        dispatch(toggleDrawer());
        setIsDrawerClosing(false);
      }, 500);
    } else dispatch(toggleDrawer());
  };

  return (
    <div
      id="drawerToggle"
      className={classnames(
        "drawerButton",
        isDrawerOpen && !isDrawerClosing ? "open" : ""
      )}
      onClick={handleToggleDrawer}
    >
      <div className="line"></div>
      <div className="line"></div>
    </div>
  );
};

export default DrawerButton;
