import React, { useState, useEffect, useRef } from "react";
// import classnames from "classnames";
import type { ComponentPropsWithoutRef } from "react";
import classnames from "classnames"
import "./banner.scss";

interface BannerProps extends ComponentPropsWithoutRef<"div"> {
  texts: string[];
  isScrolling?: boolean;
}


const Banner = ({ texts, isScrolling, ...rest }: BannerProps) => {
  const emptyString = '';
  const bannerRef = useRef<HTMLDivElement>(null);
  const [displayedText, setDisplayedText] = useState(0);
  const [mergedText, setMergedText] = useState(emptyString.concat(...texts));

  useEffect(() => {
    if (!isScrolling) {
      setTimeout(() => {
        bannerRef?.current &&
          bannerRef?.current?.animate(
            [{ opacity: 1 }, { opacity: 0 }, { opacity: 1 }],
            {
              duration: 1000,
              easing: "ease-in-out",
            }
          );
      }, 5000);
      setTimeout(() => {
        setDisplayedText((displayedText + 1) % texts.length);
      }, 5500);
    } else {
      setMergedText(emptyString.concat(...texts));
    }
  }, [displayedText, texts, bannerRef]);

  return (
    <div className="bannerContainer" {...rest}>
      <div className="bannerBorderContainer">
        <div ref={bannerRef} className={classnames("banner", isScrolling && "scrollingBanner")}>
          {isScrolling ? <>
            {mergedText}
          </> :
            texts[displayedText]
          }
        </div>
        {/* {isScrolling && <div className={classnames("banner", "secondText")}>
          {mergedText}
        </div>
        } */}
      </div>
    </div >
  );
};

export default Banner;
