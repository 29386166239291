import React, { useState, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";

// import { useInView } from "react-intersection-observer";

import Banner from "../banner/banner";
import Page from "../page/page";

import "./PartnersPage.scss";
import classNames from "classnames";


const ContactPage = () => {

  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");

  return (
    <>
      {/* <div className="backPage">{focusedPlayer.name}</div> */}
      <Page>
        <Banner
          texts={[
            `Ils ont fait appel à notre arène virtuelle !`,
          ]}
        />
        <div className="partnersText">
          <div className="nameCard withVerbatim">
            <img src="./images/partners/cumg_logo.png" alt="" />
            <div className="partnerName">Com' Unity Media group</div>
            <div className="verbatim">"Nous avons fait appel à Shotam lors de notre événement Mang'A'Bernes et leur animation VR était superbe ! Petits et grands se sont prêté au jeu et ont appréciés s'affronter dans leur arène virtuelle. Un bon divertissement familial !"</div>
          </div>
          <div className="nameCard withVerbatim">
            <img src="./images/partners/cumg_logo.png" alt="" />
            <div className="partnerName">Alexandre D.</div>
            <div className="verbatim">"Shotam est sans doute le jeu qui vous rappelle les animés avec lesquels vous avez grandi. Une arène virtuelle dans laquelle on peut affronter ses amis ou sa famille en se prenant pour l'un de ses plus grands héros de manga !"</div>
          </div>
          <div className="nameCard">
            <img src="./images/partners/RetroGeekFestival_logo.png" alt="" />
            <div className="partnerName">Retro Geek Festival</div>
          </div>
          <div className="nameCard">
            <img src="./images/partners/ExpoMangaLagny_logo.png" alt="" />
            <div className="partnerName">Expo Manga Lagny</div>
          </div>
          <div className="nameCard">
            <img src="./images/partners/epita_Logo.png" alt="" />
            <div className="partnerName">Epitanime</div>
          </div>
          <div className="nameCard">
            <img src="./images/partners/cmjosny_logo.jpg" alt="" />
            <div className="partnerName">La CMJ d'Osny</div>
            <div className="verbatim"></div>
          </div>
        </div>
      </Page>
    </>
  );
};

export default ContactPage;
