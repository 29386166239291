export const CELLS_TYPES: any = {
  land: { label: "land" },
  littoral: {
    label: "littoral",
    name: "Eaux calmes",
    description:
      "Zone de mer aisément navigable, généralement située à proximité d'une terre.",
    picture: "images/littoral.png",
  },
  deepWater: {
    label: "deepWater",
    name: "Eaux profondes",
    description: "Zone de mer difficilement navigable.",
    picture: "images/deepWater.png",
  },
  unknown: {
    label: "unknown",
    name: "Eaux inconnues",
    description:
      "Zone de mer difficilement navigable. Celà dit, rien n'est sûr, cette zone n'a pas encore été explorée ... ",
    picture: "images/deepWater.png",
  },
};

export const MAP_DATA = [
  {
    x: 14,
    y: 14,
    type: CELLS_TYPES.land,
    name: "Ile Tonaru",
    description:
      "C'est la première île connue du Shokai. C'est essentiellement un arène flottante.",
    picture: "images/arene_tonaru_2.png",
    iconPath: "images/arene_tonaru_mapIcon.png",
  },
  {
    x: 17,
    y: 10,
    type: CELLS_TYPES.land,
    name: "Ile Nebumi",
    description:
      "Île la plus proche de l'île Tonaru, mais ne semble pas particulièrement habitée. Assez sauvage, c'est cependant un bon lieu pour accoster des bateaux.",
  },
  { x: 19, y: 9, type: CELLS_TYPES.littoral },
  { x: 16, y: 8, type: CELLS_TYPES.littoral },
  { x: 16, y: 9, type: CELLS_TYPES.littoral },
  { x: 16, y: 8, type: CELLS_TYPES.littoral },
  { x: 16, y: 10, type: CELLS_TYPES.littoral },
  { x: 13, y: 16, type: CELLS_TYPES.littoral },
  { x: 17, y: 10, type: CELLS_TYPES.littoral },
  { x: 18, y: 10, type: CELLS_TYPES.littoral },
  { x: 17, y: 9, type: CELLS_TYPES.littoral },
  { x: 17, y: 11, type: CELLS_TYPES.littoral },
  { x: 18, y: 11, type: CELLS_TYPES.littoral },
  {
    x: 31,
    y: 4,
    type: CELLS_TYPES.land,
    name: "Ile Liborun",
    description: "Ile inexplorée renfermant bien des mystères.",
  },
  { x: 29, y: 4, type: CELLS_TYPES.littoral },
  { x: 30, y: 3, type: CELLS_TYPES.littoral },
  {
    x: 33,
    y: 6,
    type: CELLS_TYPES.land,
    name: "Ile Shinoki",
    description: "Ile inexplorée renfermant bien des mystères.",
  },
  { x: 33, y: 6, type: CELLS_TYPES.littoral },
  { x: 32, y: 6, type: CELLS_TYPES.littoral },
  { x: 31, y: 6, type: CELLS_TYPES.littoral },
  { x: 30, y: 6, type: CELLS_TYPES.littoral },
  { x: 29, y: 6, type: CELLS_TYPES.littoral },
  { x: 28, y: 6, type: CELLS_TYPES.littoral },
  { x: 29, y: 7, type: CELLS_TYPES.littoral },
  { x: 28, y: 7, type: CELLS_TYPES.littoral },
  { x: 30, y: 7, type: CELLS_TYPES.littoral },
  { x: 29, y: 5, type: CELLS_TYPES.littoral },
  {
    x: 32,
    y: 9,
    type: CELLS_TYPES.land,
    name: "Ile Unmoroso",
    description: "Ile inexplorée renfermant bien des mystères.",
  },
  { x: 31, y: 9, type: CELLS_TYPES.littoral },
  { x: 30, y: 9, type: CELLS_TYPES.littoral },
  { x: 29, y: 9, type: CELLS_TYPES.littoral },
  { x: 28, y: 9, type: CELLS_TYPES.littoral },
  { x: 27, y: 9, type: CELLS_TYPES.littoral },
  {
    x: 29,
    y: 12,
    type: CELLS_TYPES.land,
    name: "Ile Korosei",
    description: "Ile inexplorée renfermant bien des mystères.",
  },
  { x: 28, y: 12, type: CELLS_TYPES.littoral },
  { x: 29, y: 11, type: CELLS_TYPES.littoral },
  {
    x: 32,
    y: 15,
    type: CELLS_TYPES.land,
    name: "Ile Yippo",
    description: "Ile inexplorée renfermant bien des mystères.",
  },
  { x: 32, y: 16, type: CELLS_TYPES.littoral },
  { x: 31, y: 15, type: CELLS_TYPES.littoral },
  { x: 31, y: 14, type: CELLS_TYPES.littoral },
  {
    x: 27,
    y: 16,
    type: CELLS_TYPES.land,
    name: "Ile Felitel",
    description: "Ile inexplorée renfermant bien des mystères.",
  },
  { x: 28, y: 16, type: CELLS_TYPES.littoral },
  { x: 26, y: 17, type: CELLS_TYPES.littoral },
  {
    x: 0,
    y: 0,
    type: CELLS_TYPES.land,
    name: "Tsuchi",
    description: "Est-ce vraiment une île ?",
  },
  {
    x: 0,
    y: 19,
    type: CELLS_TYPES.land,
    name: "Sozoku",
    description: "Est-ce vraiment une île ?",
  },
  {
    x: 49,
    y: 0,
    type: CELLS_TYPES.land,
    name: "Kadan",
    description: "Est-ce vraiment une île ?",
  },
  {
    x: 49,
    y: 19,
    type: CELLS_TYPES.land,
    name: "Hidari",
    description: "Est-ce vraiment une île ?",
  },
  { x: 13, y: 14, type: CELLS_TYPES.littoral },
  //   { x: 14, y: 14, type: CELLS_TYPES.littoral },
  { x: 14, y: 15, type: CELLS_TYPES.littoral },
  { x: 13, y: 15, type: CELLS_TYPES.littoral },
  { x: 13, y: 16, type: CELLS_TYPES.littoral },
  { x: 14, y: 17, type: CELLS_TYPES.littoral },
  { x: 13, y: 17, type: CELLS_TYPES.littoral },
  { x: 12, y: 14, type: CELLS_TYPES.littoral },
  { x: 12, y: 15, type: CELLS_TYPES.littoral },
  { x: 12, y: 16, type: CELLS_TYPES.littoral },
  { x: 13, y: 16, type: CELLS_TYPES.littoral },
];
