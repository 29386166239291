import React, { useState, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";

// import { useInView } from "react-intersection-observer";

import Banner from "../banner/banner";
import Page from "../page/page";
import StatsPanel from "../statsPanel/statsPanel";

import nopicture from "../../assets/nopicture.svg";
import questionMark from "../../assets/icons/questionMark.png";
import player1_mini from "../../assets/players/player1_mini.svg";
import player1_picture from "../../assets/players/player1_picture.svg";

import alex_mini from "../../assets/players/alex_mini.png";
import alex_avatar_video from "../../assets/players/Alex_avatar_3D.mp4";
import alex_picture from "../../assets/players/alex_picture.svg";

import "./shoshiPage.scss";
import classNames from "classnames";
import shoshis from "./shoshiData";

const ShoshiPage = () => {
  const [focusedPlayer, setFocusedPlayer] = useState(shoshis[0]);
  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");
  const focusPanelRef = useRef<HTMLDivElement>(null);
  const playerNameRef = useRef<HTMLDivElement>(null);

  const selectPlayer = (player: any) => {
    if (focusPanelRef.current && player.id > -1) {
      isPortrait
        ? focusPanelRef.current.animate(
            [
              { transform: "rotateY(0deg)" },
              { transform: "rotateY(360deg)" },
              // { filter: "brightness(1) blur(0px)" },
              // { filter: "brightness(1) blur(0px)" },
              // { filter: "brightness(1) blur(0px)" },
            ],
            { duration: 500, easing: "ease-in-out" },
          )
        : focusPanelRef.current.animate(
            [
              { transform: "rotateX(0deg)" },
              { transform: "rotateX(360deg)" },
              // { filter: "brightness(1) blur(0px)" },
              // { filter: "brightness(1) blur(0px)" },
              // { filter: "brightness(1) blur(0px)" },
            ],
            { duration: 500, easing: "ease-in-out" },
          );
    }
    if (playerNameRef.current && player.id > -1) {
      playerNameRef.current.animate(
        [{ transform: "scale(4)" }, { transform: "scale(1)" }],
        { duration: 500, easing: "ease-in-out" },
      );
    }
    player.id > -1 && setFocusedPlayer(player);
  };
  return (
    <>
      {/* <div className="backPage">{focusedPlayer.name}</div> */}
      <Page>
        <Banner
          texts={[
            `Les Utawaleza: les bébés primordiaux`,
            `A leur époque, ils étaient ...`,
            `... les guerriers les plus puissants du Shotam Sekai`,
          ]}
        />
        <div ref={focusPanelRef} className="playerFocus">
          <div ref={playerNameRef} className="playerName">
            {focusedPlayer.name}
          </div>
          <StatsPanel player={focusedPlayer} />
          {!isPortrait && (
            <div
              className="playerVideo"
              style={{ backgroundImage: `url(${focusedPlayer.picture})` }}
            >
              {/* <video src={focusedPlayer.avatar}
               width="auto"
               height="100%"
               autoPlay
               loop
               muted /> */}
              {/* <img
                src={avatar_video_placeholder}
                width="100%"
                height="auto"
                alt=""
              /> */}
            </div>
          )}
        </div>

        <div className="playersContainer">
          {shoshis.map((player) => (
            <div
              className={classNames(
                "playerCard",
                focusedPlayer.id === player.id && "focusedCard",
              )}
              style={{
                backgroundImage: `url(${player.miniature})`,
              }}
              onClick={() => selectPlayer(player)}
            ></div>
          ))}
        </div>
      </Page>
    </>
  );
};

export default ShoshiPage;
