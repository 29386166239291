import React, { useState, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";

// import { useInView } from "react-intersection-observer";

import Banner from "../banner/banner";
import Page from "../page/page";

import "./ContactPage.scss";
import classNames from "classnames";


const ContactPage = () => {

  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");

  return (
    <>
      {/* <div className="backPage">{focusedPlayer.name}</div> */}
      <Page>
        <Banner
          texts={[
            `Contact & Informations`,
          ]}
          style={{ "marginTop": "100px" }}
        />
        <div className="contactText">
          Tu veux streamer ton propre évènement dans l'arène virtuelle Shotam ? <br />
          Organiser un évènement physique ? <br />
          Ou juste plus d'informations sur ce super concept ? <br /><br />

          On est jamais très loin, contacte-nous à l'adresse suivante: <br /><br />
          contact@shotam.app
        </div>
      </Page>
    </>
  );
};

export default ContactPage;
