import React, { useState } from "react";
// import { useInView } from "react-intersection-observer";

import Modal from "../modal/modal";
import Page from "../page/page";
import Banner from "../banner/banner";
import CardStatsPanel from "../cardStatsPanel/cardStatsPanel";

import shokuSkeleton from "../../assets/cards/shoku_hidden.png";
import rotateIcon from "../../assets/icons/rotate.png";
import zoomIcon from "../../assets/icons/zoom.png";

import "./cardsPage.scss";
import { collectibleCards } from "./cardData";
import classnames from "classnames";
import { useMediaQuery } from "@react-hook/media-query";

const CardDisplayer = ({ card }: { card?: any }) => {
  const [isTurned, setIsTurned] = useState(false);

  const toggleTurn = () => {
    setIsTurned(!isTurned);
  };
  return (
    <div className="cardDisplayer">
      <div
        className={classnames("cardElement", isTurned && "turned")}
        onClick={() => toggleTurn()}
      >
        <img
          className="cardFace cardRecto"
          //@ts-ignore
          src={card?.picture}
          height="auto"
          width="auto"
          alt=""
        />
        <div className="cardFace cardVerso">{card?.description}</div>
      </div>
      <div className="rotateButton" onClick={() => toggleTurn()}>
        <img
          src={rotateIcon}
          className="rotateIcon"
          height="30px"
          width="30px"
          alt=""
        />
      </div>
    </div>
  );
};

const CardsPage = () => {
  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [focusedCard, setFocusedCard] = useState(null);
  const [statsMode, setStatsMode] = useState(false);

  const handleCardClick = (card: any) => {
    if (card.id > -1) {
      handleCardZoom(card);
      setStatsMode(true);
      setFocusedCard(card);
    }
  };

  const handleCardZoom = (card: any) => {
    if (card.id > -1) {
      setFocusedCard(card);
      const cardElement = document.getElementById(card.id);
      if (cardElement) {
        cardElement?.animate(
          [{ transform: "rotateY(0deg)" }, { transform: "rotateY(360deg)" }],
          { duration: 500, easing: "ease-out" },
        );
      }
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <Modal handleClose={handleModalClose}>
          <CardDisplayer card={focusedCard} />
        </Modal>
      )}
      {/* <div className="backPage">
        Stats: 1 Nom: Shagan Qté: 2 Exploration :2 Circulation : 0 Evolutions: 9
      </div> */}
      <Page>
        <Banner
          texts={[
            `Les Shokus sont des cartes renfermant des capacités spéciales`,
            `Un système de statistiques avancées permettra de les obtenir.`,
            `Les shokus de génération 0 sont les plus faibles  sur le plan stratégique...`,
            `...mais les plus puissants sur le plan mathématique.`,
          ]}
        />
        {/* @ts-ignore */}

        {focusedCard && statsMode ? (
          <CardStatsPanel
            cardData={focusedCard}
            openCard={(card) => {
              if (card.id > -1) handleCardZoom(card);
            }}
          />
        ) : (
          <></>
        )}
        <div className={classnames("cardsContainer", statsMode && "dense")}>
          {collectibleCards.map((card, index) => (
            <div
              id={card.id}
              className={classnames("collectibleCard")}
              onClick={() => handleCardClick(card)}
              style={{ animationDelay: `${100 * index}ms` }}
            >
              <img src={card.picture ?? shokuSkeleton} alt="" />
              <button
                className="openModalButton"
                onClick={() => {
                  if (card.id > -1) handleCardZoom(card);
                }}
              >
                <img
                  src={zoomIcon}
                  className="zoomIcon"
                  height="30px"
                  width="30px"
                  alt=""
                />
              </button>
            </div>
          ))}
        </div>
      </Page>
    </>
  );
};

export default CardsPage;
