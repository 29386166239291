import questionMark from "../../assets/icons/questionMark.png";
import agaaga_picture from "../../assets/players/AgaAga_Character.png";
import ouinouin_picture from "../../assets/players/OuinOuin_character.png";
import volumvolum_picture from "../../assets/players/VolumVolum_character.png";
import bopabopa_picture from "../../assets/players/BopaBopa_character.png";

import agaaga_mini from "../../assets/players/Agaaga_mini.png";
import ouinouin_mini from "../../assets/players/OuinOuin_mini.png";
import volumvolum_mini from "../../assets/players/VolumVolum_mini.png";
import bopabopa_mini from "../../assets/players/BopaBopa_mini.png";

const shoshis: any[] = [
  {
    id: 1,
    name: "AgaAga",
    miniature: agaaga_mini,
    avatar: null,
    picture: agaaga_picture,
    idData: {
      age: "405",
      description: "Utawaleza",
    },
    statsGame: {
      shokus: 3,
      visitedIslands: 100,
    },
    stats1: {
      attack: 1,
      defense: 1,
      speed: 1,
    },
    stats2: {
      water: 1,
      earth: 1,
      wind: 1,
      fire: 1,
      ether: "N.A",
    },
  },
  {
    id: 1,
    name: "OuinOuin",
    miniature: ouinouin_mini,
    avatar: null,
    picture: ouinouin_picture,
    idData: {
      age: "405",
      description: "Utawaleza",
    },
    statsGame: {
      shokus: 3,
      visitedIslands: 100,
    },
    stats1: {
      attack: 1,
      defense: 1,
      speed: 1,
    },
    stats2: {
      water: 1,
      earth: 1,
      wind: 1,
      fire: 1,
      ether: "N.A",
    },
  },
  {
    id: 1,
    name: "BopaBopa",
    miniature: bopabopa_mini,
    avatar: null,
    picture: bopabopa_picture,
    idData: {
      age: "405",
      description: "Utawaleza",
    },
    statsGame: {
      shokus: 3,
      visitedIslands: 100,
    },
    stats1: {
      attack: 1,
      defense: 1,
      speed: 1,
    },
    stats2: {
      water: 1,
      earth: 1,
      wind: 1,
      fire: 1,
      ether: "N.A",
    },
  },
  {
    id: 1,
    name: "VolumVolum",
    miniature: volumvolum_mini,
    avatar: null,
    picture: volumvolum_picture,
    idData: {
      age: "405",
      description: "Utawaleza",
    },
    statsGame: {
      shokus: 3,
      visitedIslands: 100,
    },
    stats1: {
      attack: 1,
      defense: 1,
      speed: 1,
    },
    stats2: {
      water: 1,
      earth: 1,
      wind: 1,
      fire: 1,
      ether: "N.A",
    },
  },
  { id: -1, name: undefined, miniature: questionMark, picture: undefined },
  { id: -1, name: undefined, miniature: questionMark, picture: undefined },
  { id: -1, name: undefined, miniature: questionMark, picture: undefined },
  { id: -1, name: undefined, miniature: questionMark, picture: undefined },
];

export default shoshis;
